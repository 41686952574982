import React, { useState, useEffect, useCallback  } from "react";
import CookingTimer from "./CookingTimer";
import PopObject from '../assets/animations/pop-object';
import OneUpAnimation from '../assets/animations/one-up';
import OneDownAnimation from '../assets/animations/one-down';
import useAudioManager from "./AudioManager";


const TimedObject = ({ object, intervals, onClickResult, onRemove, isInvisible = false }) => {
    const { playSound, stopSound } = useAudioManager();
    const timeCreated = Date.now();
    const maxAllowedTime = intervals.reduce((acc, curr) => acc + curr, 0);
    const [timerActive, setTimerActive] = useState(true);
    const [timerExpired, setTimerExpired] = useState(false);
    const [timerStopped, setTimerStopped] = useState(false);
    const [timeElapsed, setTimeElapsed] = useState(0);

     // Function to determine if the object was clicked during the "green" interval
     const clickedWhenGreen = useCallback((time) => {
        return time >= intervals[0] && time < intervals[0] + intervals[1];
    }, [intervals]);

    // Effect to handle timer expiration
    useEffect(() => {
        playSound('timerTick');
        const timer = setTimeout(() => {
            setTimerActive(false);
            setTimerExpired(true);
            // Notify parent component that the object expired without being clicked
            if (onRemove) onRemove(false);
            stopSound('timerTick');
        }, maxAllowedTime * 1000);
        
        // Clean up the timer if the component unmounts
        return () => clearTimeout(timer);
    }, [maxAllowedTime, onRemove]);

    // Handle click on the object
    const handleClick = () => {
        const now = Date.now();
        const elapsedTime = (now - timeCreated) / 1000;
        setTimeElapsed(elapsedTime);
        setTimerStopped(true);
        setTimerActive(false);
        stopSound('timerTick');
        
        // Determine if the click was during the "green" interval
        const clickedCorrectly = clickedWhenGreen(elapsedTime);
        console.log('Clicked correctly:', clickedCorrectly, 'Time elapsed:', elapsedTime);
        
        // Notify parent component of the click result
        if (onRemove) {
            onRemove(clickedCorrectly);
        }
    };

    const clickedAnimation = ( // set animation shown after click based on time elapsed
        <>
            {clickedWhenGreen(timeElapsed) ?
            <OneUpAnimation object={object} /> :  // set if object clicked during 'green' interval
            <OneDownAnimation object={object} />} {/* set if object clicked outside of 'green' interval */}
        </>
    );

    if (isInvisible) {
        return (
            <div 
                onClick={handleClick} 
                style={{ 
                    width: '50%', 
                    height: '100%', 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    zIndex: 10,
                    cursor: 'pointer'
                }}
            >
              {timerActive && (
                    <>
                            <CookingTimer intervals={intervals} />
                        <div style={{ opacity: 0 }}>
                            {object}
                        </div>
                    </>
                )}
            </div>
        );
    }

    return (
        
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            {timerActive ? (
                // object with timer
                <>
                    <CookingTimer intervals={intervals} />
                    <div onClick={handleClick}>
                        {object}
                    </div>
                </>
            ) : (
                timerExpired && !timerStopped ? (
                    // show 'pop' animation if timer expired and object was not clicked
                    <PopObject object={object} />
                ) : (
                    // show clicked animation otherwise
                    clickedAnimation
                )
            )}
        </div>
        
    );
};

export default TimedObject;