import React from 'react';
import LevelTemplate from './levelTemplate';
// import Bacon from '../ingredients/Bacon';
import Egg from '../ingredients/Egg';
// import Toast from '../ingredients/Toast';
import { ignames, IG } from '../ingredients/ingredient';
import bacon_and_eggs_img from '../../assets/images/recipes/bacon_and_eggs.svg';

const Level1 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'Bacon and Eggs',
    image: bacon_and_eggs_img,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Bacon: { 
      count: 2, 
      // component: <Bacon />, 
      component: <IG name={ignames.BACON} />, 
      cookingArea: 'Stove', 
      intervals: [3, 5, 2], // [too early, perfect, too late] intervals in seconds
      timed: true 
    },
    Egg: { 
      count: 2, 
      // component: <Egg />,
      component: <IG name={ignames.EGG} />, 
      cookingArea: 'Stove', 
      intervals: [3, 5, 2],
      timed: true 
    }
  };

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={1}
      recipe={recipe}
      timeLimit={20} // Time limit in seconds
      requiredIngredients={requiredIngredients}
    />
  );
};

export default Level1;