import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './animation-styles/timerbar.css';

const TimerBar = ({ 
    yellowInterval, 
    greenInterval, 
    redInterval, 
    onRestart 
}) => {
  const timelineRef = useRef(null);
  const barWipeRef = useRef(null);
  const indicatorRef = useRef(null);

  useEffect(() => {
    // Clear any existing animation
    if (timelineRef.current) {
      timelineRef.current.kill();
    }

    // Set initial states
    gsap.set(barWipeRef.current, { backgroundColor: '#FFFF00' });
    gsap.set(indicatorRef.current, { left: '0%' });

    const timeline = gsap.timeline();
    const durationTotal = yellowInterval + greenInterval + redInterval;

    timeline.to(barWipeRef.current, {
      duration: durationTotal,
      ease: 'none',
      keyframes: [
        { backgroundColor: '#FFFF00', duration: yellowInterval },
        { backgroundColor: '#55FF55', duration: .2 },
        { backgroundColor: '#55FF55', duration: greenInterval },
        { backgroundColor: '#FF0000', duration: 1 },
        { backgroundColor: '#FF0000', duration: redInterval }
      ]
    });

    timeline.to(indicatorRef.current, 
      { left: '30%', duration: yellowInterval, ease: 'none' }, 
      0
    )
    .to(indicatorRef.current, 
      { left: '60%', duration: greenInterval, ease: 'none' }, 
      yellowInterval
    )
    .to(indicatorRef.current, 
      { left: '90%', duration: redInterval, ease: 'none' }, 
      yellowInterval + greenInterval
    );

    timelineRef.current = timeline;

    // Start the animation
    timeline.play();

    return () => {
      if (timelineRef.current) {
        timelineRef.current.kill();
      }
    };
  }, [yellowInterval, greenInterval, redInterval]);

  return (
    <div className="timer-container">
      <div ref={barWipeRef} className="bar-wipe"></div>
      <div ref={indicatorRef} className="indicator"></div>
    </div>
  );
};

export default React.memo(TimerBar);