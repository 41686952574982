import React, { useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';

import starFilled from '../../assets/images/other/filled-star.svg';
import starEmpty from '../../assets/images/other/grey-star.svg';
import './pages/levelSelect.css';

const LevelSelect = () => {
  const navigate = useNavigate();
  const [starScores, setStarScores] = useState({});

  // starscores fetched here
  useEffect(() => {
    const fetchStarScores = async () => {
      const userId = localStorage.getItem('userID');
      if (userId) {
        try {
          const response = await fetch(`http://localhost:3001/users/${userId}`);
          const userData = await response.json();
          
          if (response.ok && userData.starScores) {
            setStarScores(userData.starScores);  // Update state with starScores from the DB
          } else {
            console.error('Error fetching user data: ', userData.error || 'No starScores found.');
          }
        } catch (err) {
          console.error('Error fetching user data: ', err);
        }
      } else {
        console.error('No user logged in');
      }
    };

    fetchStarScores();
  }, []); 

  const levels = [
    { number: "0", route: "/level-template" },
    { number: "1", route: "/level1" },
    { number: "2", route: "/level2" },
    { number: "3", route: "/level3" },
    { number: "4", route: "/level4" },
    { number: "5", route: "/level5" },
    { number: "6", route: "/level-template" },
    { number: "7", route: "/level-template" },
    { number: "8", route: "/level-template" },
    { number: "9", route: "/level-template" },
    { number: "10", route: "/level-template" }
  ];

  const buttonAction = (route) => {
    navigate(route);
  };
  const backButton = () => {
    navigate('/');
  };

  // Render stars based on the score for each level
  const renderStars = (levelNumber) => {
    const starCount = starScores[`L${levelNumber}`] || 0;  // Get star count from starScores, default to 0 if not available
    return (
      <div className="star-rating">
        {[...Array(3)].map((_, i) => (
          <img
            key={i}
            src={i < starCount ? starFilled : starEmpty}
            alt={i < starCount ? "Filled Star" : "Empty Star"}
            className="star"
          />
        ))}
      </div>
    );
  };

  return (
    <div className="level-select">
      <h1>Select a Level</h1>
      <div className="level-buttons">
        {levels.map((level, index) => (
          <button
            key={index}
            onClick={() => buttonAction(level.route)}
            className="level-button"
          >
            <span className="level-number">Level {level.number}</span>
            {renderStars(level.number)}  {/* Pass the level number to renderStars */}
          </button>
        ))}
      </div>
      <button onClick={backButton} className="back-button">Back</button>
    </div>
  );
};

export default LevelSelect;