import React, { useState, useEffect, useRef, useCallback } from 'react';
import Egg from '../ingredients/Egg';
import Bacon from '../ingredients/Bacon';
import Sausage from '../ingredients/Sausage';
import Toast from '../ingredients/Toast';
import Mushroom from '../ingredients/Mushroom';
import Tomato from '../ingredients/Tomato';

import recipeImg from '../../assets/images/recipes/Full_English_Breakfast.png';
import './pages/levelTemplate.css';
import LevelTemplate from './levelTemplate';

const Level5 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'English Breakfast',
    image: recipeImg,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Sausage: { count: 3, 
      component: <Sausage />, 
      cookingArea: 'Stove', 
      intervals: [7, 3, 3], 
      timed: true 
    },
    Egg: { count: 2, 
        component: <Egg />, 
        cookingArea: 'Stove', 
        intervals: [3, 2, 3], 
        timed: true 
      },
      Bacon: { count: 2, 
        component: <Bacon />, 
        cookingArea: 'Stove', 
        intervals: [6, 2, 3], 
        timed: true 
      },
      Toast: { count: 2, 
        component: <Toast />, 
        cookingArea: 'Stove', 
        intervals: [9, 3, 4], 
        timed: true 
      },
      Tomato: { count: 2, 
        component: <Tomato />, 
        cookingArea: 'CuttingBoard', 
        intervals: null,
        timed: false
      },
      Mushroom: { count: 3, 
        component: <Mushroom />, 
        cookingArea: 'CuttingBoard', 
        intervals: null,
        timed: false
      },
  };

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={5}
      recipe={recipe}
      timeLimit={25} // Time limit in seconds
      requiredIngredients={requiredIngredients}
    />
  );
};

export default Level5;
