import React, { useState, useEffect, useRef, useCallback } from 'react';
import EggsBene from '../../assets/images/recipes/Eggs_benedict.png';
import './pages/levelTemplate.css';
// import Egg from '../ingredients/Egg';
// import Toast from '../ingredients/Toast';
// import Avocado from '../ingredients/Avocado';
import { ignames, IG } from '../ingredients/ingredient';
import LevelTemplate from './levelTemplate';

const Level2 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'Eggs Benedict',
    image: EggsBene,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Toast: { count: 1, 
      // component: <Toast />, replace Toast by IG
      component: <IG name={ignames.TOAST} />, 
      cookingArea: 'Toaster', 
      intervals: [5, 3, 3], 
      timed: true 
    },
    Egg: { count: 2, 
      // component: <Egg />, replace Egg by IG
      component: <IG name={ignames.EGG} />, 
      cookingArea: 'Stove', 
      intervals: [3, 5, 2], 
      timed: true
    },
    Avocado: {count: 1, 
      // component: <Avocado />, replace Avocado by IG
      component: <IG name={ignames.AVOCADO} />, 
      cookingArea: 'CuttingBoard', 
      intervals: null, 
      timed: false }
  };

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={2}
      recipe={recipe}
      timeLimit={20} // Time limit in seconds
      requiredIngredients={requiredIngredients}
    />
  );
};

export default Level2;
