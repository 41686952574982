import React from 'react';
import PropTypes from 'prop-types';
import './navigation/pages/endScreens.css';

const IntroScreen = ({ recipeName, recipeImg, timeLimit, onStart }) => {
  return (
    <div className="overlay-screen">
      <div className="overlay-content">
        <h2 className="overlay-title">{recipeName}</h2>
        <div className="recipe-image-container">
          <img src={recipeImg} alt={recipeName} className="intro-image" />
        </div>
        <p className="overlay-info">Time Limit: {timeLimit} seconds</p>
        <div className="overlay-button-container">
          <button className="overlay-button play-button" onClick={onStart}>Play Level</button>
        </div>
      </div>
    </div>
  );
};

IntroScreen.propTypes = {
  recipeName: PropTypes.string.isRequired,
  recipeImg: PropTypes.string.isRequired,
  timeLimit: PropTypes.number.isRequired,
  onStart: PropTypes.func.isRequired,
};

export default IntroScreen;