import React, { useState, useEffect, useRef, useCallback } from 'react';
import Steak from '../ingredients/Steak';
import Broccoli from '../ingredients/Broccoli';
import Potato from '../ingredients/Potato';
import Carrot from '../ingredients/Carrot';

import recipeImg from '../../assets/images/recipes/Steak_and_Veggies.png';
import './pages/levelTemplate.css';
import LevelTemplate from './levelTemplate';

const Level4 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'Steak and Veggies',
    image: recipeImg,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Steak: { count: 3, 
      component: <Steak />, 
      cookingArea: 'Stove', 
      intervals: [9, 3, 3], 
      timed: true 
    },
    Broccoli: { count: 3, 
        component: <Broccoli />, 
        cookingArea: 'Stove', 
        intervals: [3, 2, 3], 
        timed: true 
      },
      Potato: { count: 2, 
        component: <Potato />, 
        cookingArea: 'CuttingBoard', 
        intervals: null,
        timed: false
      },
      Carrot: { count: 3, 
        component: <Carrot />, 
        cookingArea: 'CuttingBoard', 
        intervals: null,
        timed: false
      },
  };

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={4}
      recipe={recipe}
      timeLimit={20} // Time limit in seconds
      requiredIngredients={requiredIngredients}
    />
  );
};

export default Level4;
