import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import RecipeBoard from './RecipeBoard';
import Toaster from './cookware/Toaster';

const PlayArea = forwardRef(({ 
  recipe,
  imageSrc,
  clock,
  zones, 
  initialObjects, 
  testing=false 
}, ref) => {
  const imgRef = useRef(null);
  const [areas, setAreas] = useState([]);
  const [objects, setObjects] = useState(initialObjects);
  const [toasterState, setToasterState] = useState({ left: 0, right: 0 });
  const boardRecipe = recipe;

  function moveObject(objectIndex, newKey) {
    const updatedObjects = [...objects];
    updatedObjects[objectIndex].key = newKey;
    setObjects(updatedObjects);
  };
  
  function removeObject(objectIndex) {
    moveObject(objectIndex, '');
  };

  function addObject(object, key) {
    const updatedObjects = [...objects];
    updatedObjects.push({ key, content: object });
    setObjects(updatedObjects);

    // Update toaster state if the object is added to the toaster
    if (key === 'Toaster-Left' || key === 'Toaster-Right') {
      setToasterState(prevState => ({
        ...prevState,
        [key === 'Toaster-Left' ? 'left' : 'right']: 1
      }));
    }
  };

  function updateToasterState(side, value) {
    setToasterState(prevState => ({
      ...prevState,
      [side]: value
    }));
  }

  useImperativeHandle(ref, () => ({
    moveObject,
    removeObject,
    addObject,
    getObjects: () => objects,
    updateToasterState
  }));

  /**
   * When there are less than 3 seconds, set the clock to alert style.
   * Alert style: font becomes to red and bigger.
   */
  const [isAlert, setIsAlert] = useState(false);

  useEffect(() => {
    setIsAlert(parseInt(clock.slice(3,5)) <= 5);
  }, [clock]);

  useEffect(() => {
    const updateAreas = () => {
      if (imgRef.current) {
        const imgWidth = imgRef.current.clientWidth;
        const imgHeight = imgRef.current.clientHeight;

        const newAreas = zones.map(coord => {
          const { shape, coords, href, alt } = coord;
          const pixelCoords = coords.map((percent, index) => {
            return index % 2 === 0
              ? (percent / 100) * imgWidth
              : (percent / 100) * imgHeight;
          });

          return { shape, coords: pixelCoords, href, alt };
        });

        setAreas(newAreas);
      }
    };

    updateAreas();
    window.addEventListener('resize', updateAreas);

    return () => {
      window.removeEventListener('resize', updateAreas);
    };
  }, [zones]);

  return (
    <div style={{ position: 'relative' }}>
      <img ref={imgRef} src={imageSrc} alt="Example" style={{ display: 'block', width: '100%' }} />
      <div style={{
        position: 'absolute', 
        top: '10px', 
        left: '5px', 
        display: 'flex', 
        flexDirection: 'column', 
        width: 'fit-content', 
        marginTop: '1px', 
        marginLeft: '5px'
      }}>
        <RecipeBoard recipe={boardRecipe} />
      </div>
      <div 
        className={`clock-container ${isAlert ? ' alert' : ' normal'}`}
        style={{
        position: 'absolute',
        top: '10px', 
        right: '30px', 
        display: 'flex', 
        flexDirection: 'column', 
        width: 'fit-content', 
        marginTop: '1px', 
        marginRight: '5px', 
        // fontSize: '30px', 
        // color: 'white',
        // fontFamily: 'Arial, sans-serif',
        backgroundColor: '#8B4513',
        padding: '10px',
        borderRadius: '5px',
      }}>
        {clock}
      </div>
      <h1 style={{ 
        position: 'absolute',
        top: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        width: '100%' 
      }}>
        {recipe.name}
      </h1>
      {areas.map((area, index) => (
        <div
          key={index}
          href={area.href}
          alt={area.alt}
          style={{
            position: 'absolute',
            left: `${area.coords[0]}px`,
            top: `${area.coords[1]}px`,
            width: `${area.coords[2] - area.coords[0]}px`,
            height: `${area.coords[3] - area.coords[1]}px`,
            backgroundColor: testing ? 'rgba(128, 128, 128, 0.8)' : 'transparent',
            display: 'block'
          }}>
            {/* Updated to display objects horizontally */}
            <div style={{ 
              position: 'relative', 
              width: '100%', 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'row', 
              flexWrap: 'wrap', 
              alignItems: 'center', 
              justifyContent: 'flex-start' 
            }}>
              {objects.map((object, objIndex) => (
                object.key === area.alt ? (
                  <div
                    key={objIndex} 
                    onClick={object.content.props.onClick}
                    style={{ margin: '3px' }} // Add some spacing between objects
                  >
                    {object.content}
                  </div>
                ) : null
              ))}
              {area.alt === 'Toaster' && (
                <Toaster leftState={toasterState.left} rightState={toasterState.right} />
              )}
            </div>
          </div>
      ))}
    </div>
  );
});

export default PlayArea;