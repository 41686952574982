import React, { useState, useEffect, useRef, useCallback } from 'react';
import Pancake from '../ingredients/Pancake';
import Strawberry from '../ingredients/Strawberry';
import Banana from '../ingredients/Banana';

import Pancakes from '../../assets/images/recipes/Pancakes.png';
import './pages/levelTemplate.css';
import LevelTemplate from './levelTemplate';

const Level3 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'Pancakes',
    image: Pancakes,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Pancake: { count: 3, 
      component: <Pancake />, 
      cookingArea: 'Stove', 
      intervals: [7, 2, 2], 
      timed: true 
    },
    Banana: { count: 4, 
      component: <Banana />, 
      cookingArea: 'CuttingBoard', 
      intervals: null,
      timed: false
    },
    Strawberry: { count: 4, 
      component: <Strawberry />, 
      cookingArea: 'CuttingBoard', 
      intervals: null,
      timed: false
    },
  };

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={3}
      recipe={recipe}
      timeLimit={20} // Time limit in seconds
      requiredIngredients={requiredIngredients}
    />
  );
};

export default Level3;
