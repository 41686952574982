import Ingredient from "../Ingredient";

import salmon_fillet1 from "../../assets/images/ingredients/unknown1.png";
import salmon_fillet2 from "../../assets/images/ingredients/unknown2.png";
import pepper1 from "../../assets/images/ingredients/unknown1.png";
import pepper2 from "../../assets/images/ingredients/unknown2.png";
import tomato1 from "../../assets/images/ingredients/wholeTomato.svg";
import tomato2 from "../../assets/images/ingredients/tomatoSlice.svg";
import salt1 from "../../assets/images/ingredients/unknown1.png";
import salt2 from "../../assets/images/ingredients/unknown2.png";
import unsalted_butter1 from "../../assets/images/ingredients/unknown1.png";
import unsalted_butter2 from "../../assets/images/ingredients/unknown2.png";
import cheese1 from "../../assets/images/ingredients/unknown1.png";
import cheese2 from "../../assets/images/ingredients/unknown2.png";
import fish_fillet1 from "../../assets/images/ingredients/unknown1.png";
import fish_fillet2 from "../../assets/images/ingredients/unknown2.png";
import potato1 from "../../assets/images/ingredients/unknown1.png";
import potato2 from "../../assets/images/ingredients/unknown2.png";
import kangaroo_fillets1 from "../../assets/images/ingredients/unknown1.png";
import kangaroo_fillets2 from "../../assets/images/ingredients/unknown2.png";
import lemon1 from "../../assets/images/ingredients/unknown1.png";
import lemon2 from "../../assets/images/ingredients/unknown2.png";
import green_onion1 from "../../assets/images/ingredients/unknown1.png";
import green_onion2 from "../../assets/images/ingredients/unknown2.png";
import onion1 from "../../assets/images/ingredients/unknown1.png";
import onion2 from "../../assets/images/ingredients/unknown2.png";
import carrots1 from "../../assets/images/ingredients/unknown1.png";
import carrots2 from "../../assets/images/ingredients/unknown2.png";
import soy_sauce1 from "../../assets/images/ingredients/unknown1.png";
import soy_sauce2 from "../../assets/images/ingredients/unknown2.png";
import rice1 from "../../assets/images/ingredients/unknown1.png";
import rice2 from "../../assets/images/ingredients/unknown2.png";
import garlic1 from "../../assets/images/ingredients/unknown1.png";
import garlic2 from "../../assets/images/ingredients/unknown2.png";
import cherry_tomatoes1 from "../../assets/images/ingredients/unknown1.png";
import cherry_tomatoes2 from "../../assets/images/ingredients/unknown2.png";
import egg1 from "../../assets/images/ingredients/rawEgg.svg";
import egg2 from "../../assets/images/ingredients/egg.svg";
import feta1 from "../../assets/images/ingredients/unknown1.png";
import feta2 from "../../assets/images/ingredients/unknown2.png";
import meat1 from "../../assets/images/ingredients/unknown1.png";
import meat2 from "../../assets/images/ingredients/unknown2.png";
import chicken1 from "../../assets/images/ingredients/unknown1.png";
import chicken2 from "../../assets/images/ingredients/unknown2.png";
import can_beans1 from "../../assets/images/ingredients/unknown1.png";
import can_beans2 from "../../assets/images/ingredients/unknown2.png";
import sausage1 from "../../assets/images/ingredients/unknown1.png";
import sausage2 from "../../assets/images/ingredients/unknown2.png";
import lemon_myrtle_leaves1 from "../../assets/images/ingredients/unknown1.png";
import lemon_myrtle_leaves2 from "../../assets/images/ingredients/unknown2.png";
import basil_leaves1 from "../../assets/images/ingredients/unknown1.png";
import basil_leaves2 from "../../assets/images/ingredients/unknown2.png";
import balsamic_vinegar1 from "../../assets/images/ingredients/unknown1.png";
import balsamic_vinegar2 from "../../assets/images/ingredients/unknown2.png";
import lettuce1 from "../../assets/images/ingredients/unknown1.png";
import lettuce2 from "../../assets/images/ingredients/unknown2.png";
import pizza_crust1 from "../../assets/images/ingredients/unknown1.png";
import pizza_crust2 from "../../assets/images/ingredients/unknown2.png";
import olive_oil1 from "../../assets/images/ingredients/unknown1.png";
import olive_oil2 from "../../assets/images/ingredients/unknown2.png";
import corn1 from "../../assets/images/ingredients/unknown1.png";
import corn2 from "../../assets/images/ingredients/unknown2.png";
import bacon1 from "../../assets/images/ingredients/bacon.svg";
import bacon2 from "../../assets/images/ingredients/bacon.svg";
import avocado1 from "../../assets/images/ingredients/Avacado_half.png";
import avocado2 from "../../assets/images/ingredients/Avacado.png";
import patty1 from "../../assets/images/ingredients/RawPatty.png";
import patty2 from "../../assets/images/ingredients/CookedPatty.png";
import steak1 from "../../assets/images/ingredients/Raw_steak.png";
import steak2 from "../../assets/images/ingredients/Cooked_Steak.png";
import toast1 from "../../assets/images/ingredients/toast.svg";
import toast2 from "../../assets/images/ingredients/toast.svg";

/**
 * Standard ingredient names.
 */
const ignames = {
    SALMON_FILLET: "salmon fillet",
    PEPPER: "pepper",
    TOMATO: "tomato",
    SALT: "salt",
    UNSALTED_BUTTER: "unsalted butter",
    CHEESE: "cheese",
    FISH_FILLET: "fish fillet",
    POTATO: "potato",
    KANGAROO_FILLETS: "kangaroo fillets",
    LEMON: "lemon",
    GREEN_ONION: "green onion",
    ONION: "onion",
    CARROTS: "carrots",
    SOY_SAUCE: "soy sauce",
    RICE: "rice",
    GARLIC: "garlic",
    CHERRY_TOMATOES: "cherry tomatoes",
    EGG: "egg",
    FETA: "feta",
    MEAT: "meat",
    CHICKEN: "chicken",
    CAN_BEANS: "can beans",
    SAUSAGE: "sausage",
    LEMON_MYRTLE_LEAVES: "lemon myrtle leaves",
    BASIL_LEAVES: "basil leaves",
    BALSAMIC_VINEGAR: "balsamic vinegar",
    LETTUCE: "lettuce",
    PIZZA_CRUST: "pizza crust",
    OLIVE_OIL: "olive oil",
    CORN: "corn",
    BACON: "bacon",
    AVOCADO: "avocado",
    PATTY: "patty",
    STEAK: "steak",
    TOAST: "toast",
}

/**
 * Generate an ingredient based on the name
 * The names list can be viewed by using ignames, e.g: ignames.EGG
 * @param {string} name ingredients name, like egg
 * @returns 
 */
const IG = ({name, changeable=true, hideable=true, onClick, changed}) => {
    const lower_name = name.toLowerCase()

    // ingredients dict: including all names, images of ingredients
    // 
    const ingredients = [
        {
            name: ignames.SALMON_FILLET,
            image: [salmon_fillet1, salmon_fillet2]
        },
        {
            name: ignames.PEPPER,
            image: [pepper1, pepper2]
        },
        {
            name: ignames.TOMATO,
            image: [tomato1, tomato2]
        },
        {
            name: ignames.SALT,
            image: [salt1, salt2]
        },
        {
            name: ignames.UNSALTED_BUTTER,
            image: [unsalted_butter1, unsalted_butter2]
        },
        {
            name: ignames.CHEESE,
            image: [cheese1, cheese2]
        },
        {
            name: ignames.FISH_FILLET,
            image: [fish_fillet1, fish_fillet2]
        },
        {
            name: ignames.POTATO,
            image: [potato1, potato2]
        },
        {
            name: ignames.KANGAROO_FILLETS,
            image: [kangaroo_fillets1, kangaroo_fillets2]
        },
        {
            name: ignames.LEMON,
            image: [lemon1, lemon2]
        },
        {
            name: ignames.GREEN_ONION,
            image: [green_onion1, green_onion2]
        },
        {
            name: ignames.ONION,
            image: [onion1, onion2]
        },
        {
            name: ignames.CARROTS,
            image: [carrots1, carrots2]
        },
        {
            name: ignames.SOY_SAUCE,
            image: [soy_sauce1, soy_sauce2]
        },
        {
            name: ignames.RICE,
            image: [rice1, rice2]
        },
        {
            name: ignames.GARLIC,
            image: [garlic1, garlic2]
        },
        {
            name: ignames.CHERRY_TOMATOES,
            image: [cherry_tomatoes1, cherry_tomatoes2]
        },
        {
            name: ignames.EGG,
            image: [egg1, egg2]
        },
        {
            name: ignames.FETA,
            image: [feta1, feta2]
        },
        {
            name: ignames.MEAT,
            image: [meat1, meat2]
        },
        {
            name: ignames.CHICKEN,
            image: [chicken1, chicken2]
        },
        {
            name: ignames.CAN_BEANS,
            image: [can_beans1, can_beans2]
        },
        {
            name: ignames.SAUSAGE,
            image: [sausage1, sausage2]
        },
        {
            name: ignames.LEMON_MYRTLE_LEAVES,
            image: [lemon_myrtle_leaves1, lemon_myrtle_leaves2]
        },
        {
            name: ignames.BASIL_LEAVES,
            image: [basil_leaves1, basil_leaves2]
        },
        {
            name: ignames.BALSAMIC_VINEGAR,
            image: [balsamic_vinegar1, balsamic_vinegar2]
        },
        {
            name: ignames.LETTUCE,
            image: [lettuce1, lettuce2]
        },
        {
            name: ignames.PIZZA_CRUST,
            image: [pizza_crust1, pizza_crust2]
        },
        {
            name: ignames.OLIVE_OIL,
            image: [olive_oil1, olive_oil2]
        },
        {
            name: ignames.CORN,
            image: [corn1, corn2]
        },
        {
            name: ignames.BACON,
            image: [bacon1, bacon2]
        },
        {
            name: ignames.AVOCADO,
            image: [avocado1, avocado2]
        },
        {
            name: ignames.PATTY,
            image: [patty1, patty2]
        },
        {
            name: ignames.STEAK,
            image: [steak1, steak2]
        },
        {
            name: ignames.TOAST,
            image: [toast1, toast2]
        }
    ]

    const my = ingredients.find((element) => element.name === lower_name)

    return (
        <Ingredient
            name={my.name}
            image={my.image}
            changeable={changeable}
            hideable={hideable}
            changed={changed}
            onClick={onClick}
        />
    );
}

export {ignames, IG}