// just a pretty home screen which has a button which will take you to the level select screen, and probably an exit button?

import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './pages/mainMenu.css';
import TutorialScreen from '../TutorialScreen';

import TutImage1 from '../../assets/images/other/Tut1.JPG';
import TutImage2 from '../../assets/images/other/Tut2.JPG';
import TutImage3 from '../../assets/images/other/Tut3.JPG';
import TutImage4 from '../../assets/images/other/Tut4.JPG';

const MainMenu = () => {
  const navigate = useNavigate();
  const [showTutorial, setShowTutorial] = useState(false);

  const tutorialImages = [
    TutImage1,
    TutImage2,
    TutImage3,
    TutImage4,
  ];

  const goToLevelSelect = () => {
    navigate('/level-select');
  };

  const learnToPlay = () => {
    setShowTutorial(true);
  };

  const goToSignIn = () => {
    navigate('/sign-in');
  };

  return (
    <div className="main-menu">
      <h1 className="main-title">Tucker Time</h1>
      <div className="button-container">
        <button onClick={goToLevelSelect} className="menu-button">
          Play Game
        </button>
        <button onClick={learnToPlay} className="menu-button how-to-play-button">
          How to Play
        </button>
        <button onClick={goToSignIn} className="menu-button">
          Sign In
        </button>
      </div>
      {showTutorial && (
        <TutorialScreen
          images={tutorialImages}
          onClose={() => setShowTutorial(false)}
        />
      )}
    </div>
  );
};


export default MainMenu;

