import React from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Routes  
} from 'react-router-dom';
import MainMenu from './navigation/mainMenu';
import LevelSelect from './navigation/pagelevelSelectScreen';
import Level1 from './navigation/level1';
import Level2 from './navigation/level2';
import Level3 from './navigation/level3';
import Level4 from './navigation/level4';
import Level5 from './navigation/level5';
import LevelTemplate from './navigation/levelTemplate';
import UserAuth from './navigation/UserAuth';

const Navigation = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<MainMenu />} />
          <Route path='/sign-in' element={<UserAuth />} />
          <Route path="/level-select" element={<LevelSelect />} />
          <Route path="/level-template" element={<LevelTemplate />} />
          <Route path="/level1" element={<Level1 />} />
          <Route path="/level2" element={<Level2 />} />
          <Route path="/level3" element={<Level3 />} />
          <Route path="/level4" element={<Level4 />} />
          <Route path="/level5" element={<Level5 />} />
        </Routes>
      </Router>
    );
  };

export default Navigation;