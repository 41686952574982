import { useEffect, useRef, useCallback } from 'react';
import successfulClickSound from '../assets/audio/CorrectClick.mp3';
import unsuccessfulClickSound from '../assets/audio/IncorrectClick.mp3';
import chopSound from '../assets/audio/VeggieCut.mp3';
import timerTick from '../assets/audio/CookingTick2.mp3';
import winSound from '../assets/audio/VictorySound.mp3';

const useAudioManager = () => {
  const audioRefs = useRef({});

  useEffect(() => {
    const audioFiles = { //add any audio files here
      successfulClick: successfulClickSound,
      unsuccessfulClick: unsuccessfulClickSound,
      chopSound: chopSound,
      timerTick: timerTick,
      winSound: winSound,
      
    };

    Object.entries(audioFiles).forEach(([key, src]) => {
      const audio = new Audio(src);
      audio.preload = 'auto';
      audioRefs.current[key] = audio;
    });

    return () => {
      Object.values(audioRefs.current).forEach(audio => audio.pause());
    };
  }, []);

  const playSound = useCallback((soundName) => {
    const audio = audioRefs.current[soundName];
    if (audio) {
      audio.currentTime = 0;
      audio.play().catch(error => console.error('sound error', error));
    } 
}, []);

  const stopSound = useCallback((soundName) => {
    console.log(`Attempting to stop sound: ${soundName}`);
    const audio = audioRefs.current[soundName];
    if (audio) {
      audio.pause();
      audio.currentTime = 0; // Reset to start
    }
  }, []);

  return { playSound, stopSound };
};

export default useAudioManager;