import React, { useState, useEffect } from "react";
import Background from "../assets/images/recipes/recipe_board.png";

import tick from "../assets/images/other/tick.svg";

const RecipeBoard = ({ recipe }) => {
  const [dimensions, setDimensions] = useState({ width: '10vw', height: 'auto', top: '10%', left: '10%' });

  // Adjust the size and position based on the window's dimensions
  const handleResize = () => {
    const newWidth = Math.min(window.innerWidth * 0.12, 350); // Scale width by 20% of viewport width, max 350px
    const newHeight = 'auto'; // Auto-height to maintain aspect ratio of the content

    const newTop = '10%';  // Keep 10% from the top of its container
    const newLeft = newTop.slice(0, -1); // Keep same distance from left as top

    setDimensions({
      width: `${newWidth}px`,
      height: newHeight,
      top: newTop,
      left: newLeft,
    });
  };

  useEffect(() => {
    handleResize(); // Initialize dimensions when component mounts
    window.addEventListener('resize', handleResize); // Listen for resizing

    return () => window.removeEventListener('resize', handleResize); // Clean up listener on unmount
  }, []);
  return (
    <div style={{
      width: dimensions.width, 
      height: dimensions.height, 
      top: dimensions.top,
      left: dimensions.left,
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      position: 'relative', 
      zIndex: 10, 
      padding: '8px', 
      boxShadow: '2px 4px 2px gray', 
      borderRadius: '12px', 
      backgroundImage: `url(${Background})`
    }}>

      {/* The recipe image and name */}
      <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
        <img src={recipe.image} alt={recipe.name} style={{ width: '80px', height: '80px', backgroundColor: 'white', borderRadius: '40px', paddingLeft: '4px' }} />
        <h2 style={{fontSize: '16px', padding: 0, margin: 0}}>{recipe.name}</h2>
      </div>

      {/* The ingredients image and quantity */}
      <ul style={{display: 'flex', flexDirection: 'column', justifyItems: 'center', justifyContent: 'space-between', paddingLeft: '20px', paddingRight: '20px', paddingTop: 0, paddingBottom: '10px', margin: 0, gap: '10px'}}>
        {recipe.ingredients.map(([ingredient, quantity], index) => (

          <li key={index} style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <span style={{padding: '0px', marginRight: '30px'}}>{ingredient}</span>
            {quantity > 0 ? <span style={{fontSize: '20px'}}>{quantity}</span> : <img src={tick} alt="tick" style={{width: '20px', height: '20px'}} />}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default RecipeBoard;