import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';


import PlayArea from '../PlayArea';

import TimedObject from '../TimedObject';
import SuccessScreen from '../SuccessScreen';
import FailureScreen from '../FailureScreen';
import IntroScreen from '../IntroScreen';

import kitchen from '../../assets/images/background/Kitchen.png';
import useAudioManager from '../AudioManager';

import {ignames, IG} from "../../components/ingredients/ingredient";

import './pages/levelTemplate.css';

const LevelTemplate = ({ 
  levelNumber,
  recipe,
  timeLimit,
  requiredIngredients
}) => {
  const navigate = useNavigate();
  const { playSound, stopSound } = useAudioManager();

  const [ingredients, setIngredients] = useState(requiredIngredients); // Array of ingredients with counts + properties
  const [choppingBoardCount, setChoppingBoardCount] = useState(0); // num of items on chopping board
  const [activeToastCount, setActiveToastCount] = useState({ left: 0, right: 0 }); // num of toast in toaster
  const [starScore, setStarScore] = useState(3); // player star score
  const [scoreModifier, setScoreModifier] = useState(0); // used to modify score
  const [showSuccess, setShowSuccess] = useState(false); // failure screen controller
  const [showFailure, setShowFailure] = useState(false); // success screen controller
  const [showIntro, setShowIntro] = useState(true); // intro screen controller
  const [isTimerRunning, setIsTimerRunning] = useState(false); // is level timer running
  const [timeTaken, setTimeTaken] = useState(0); // elapsed time in seconds
  const [clockDisplay, setClockDisplay] = useState('00:00'); //time display
  const [timeLeft, setTimeLeft] = useState(timeLimit); // initalised with timeLimit


  const playAreaRef = useRef(null);

  // Create the levelRecipe based on the recipe prop and ingredients state
  const levelRecipe = {
    name: recipe.name,
    image: recipe.image,
    ingredients: Object.entries(ingredients).map(([key, value]) => [
      React.cloneElement(value.component, {
        key: `RecipeBoard${key}`,
        changeable: false,
        hideable: false,
        onClick: () => handleIngredientClick(key)
      }),
      value.count
    ])
  };

 // Decrease the star score
 const decrementStarCount = () => {
  if (starScore > 0) setStarScore(starScore - 1);
};

// Increase the score modifier
const incrementScoreModifier = () => {
  setScoreModifier(scoreModifier + 1);
};

// Update star score in database
const updateStarScore = async () => {
  const userId = localStorage.getItem('userID');
  if (!userId) {
    console.error('No user ID found, unable to update score.');
    return;
  }
  const level = `L${levelNumber}`;
  const score = Math.max(0, starScore);

  try {
    // Send a POST request to update the user's star score
    const response = await fetch(`http://localhost:3001/update_score/id=${userId}/level=${level}/score=${score}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const result = await response.json();
    if (response.ok) {
      console.log('Score updated successfully:', result.message);
    } else {
      console.error('Error updating score:', result.error);
    }
  } catch (error) {
    console.error('Failed to update score:', error);
  }
};

// Remove an object from the play area
const handleRemoveObject = (objIndex) => {
  if (playAreaRef.current) {
    playAreaRef.current.removeObject(objIndex);
  }
};

// Add an object to the play area
const handleAddObject = (object, key) => {
  if (playAreaRef.current) {
    playAreaRef.current.addObject(object, key);
  }
};

// Handle successful completion of the level
const handleSuccess = () => {
  updateStarScore();
  setShowSuccess(true);
};

// Handle failure to complete the level
const handleFailure = () => setShowFailure(true);

// Start the level
const handleStartLevel = () => {
  setShowIntro(false);
  setIsTimerRunning(true);
};

// Timer effect
useEffect(() => {
  if (!isTimerRunning) return;  // Don't start the timer until isTimerRunning is true

  // if level complete, stop counting
  if (showSuccess || showFailure) return;

  // controls a clock display
  const interval = setInterval(() => {
    setTimeLeft(prevTime => {
      if (prevTime <= 0) {
        clearInterval(interval);
        handleFailure();
        return 0;
      }
      const newTimeleft = prevTime - 1;
      const timeTaken = timeLeft - newTimeleft;
      const minutes = Math.floor(newTimeleft / 60);
      const seconds = newTimeleft % 60;
      setClockDisplay(`${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
      
      // Update timeTaken
      const takenMinutes = Math.floor(timeTaken / 60);
      const takenSeconds = timeTaken % 60;
      setTimeTaken(`${takenMinutes < 10 ? '0' : ''}${takenMinutes}:${takenSeconds < 10 ? '0' : ''}${takenSeconds}`);
      
      return newTimeleft;
    });
  }, 1000);

  return () => clearInterval(interval);
}, [isTimerRunning, showSuccess, showFailure, timeLimit, timeLeft]);

  // Handle ingredient interaction
  const handleIngredient = (ingredientKey, side, idx, clickedCorrectly) => {
    console.log(`${ingredientKey} clicked, correct:`, clickedCorrectly);

    
    const ingredient = ingredients[ingredientKey];
    
    if (ingredientKey === 'Toast' && side) {
      setActiveToastCount(prevCount => ({
        ...prevCount,
        [side]: Math.max(0, prevCount[side] - 1)
      }));
      setTimeout(() => {
        handleRemoveObject(idx);
        playAreaRef.current.updateToasterState(side.toLowerCase(), 0);
      }, 1000); 
    }

    setChoppingBoardCount(prevCount => {
      setIngredients(prev => {
        if (prev[ingredientKey].count <= 0) return prev;
        if (ingredient.timed && !clickedCorrectly) {
          decrementStarCount();
          
          setTimeout(() => {
            handleRemoveObject(idx);
          }, 1000); 
          
          playSound('unsuccessfulClick')
          return prev;
          
        }
        if (!ingredient.timed) {
          if (prevCount === 1) return prev;
          incrementScoreModifier();
          handleRemoveObject(idx);
          playSound('chopSound')
        } else {
          incrementScoreModifier();
        }
        playSound('successfulClick')
        setTimeout(() => {
          handleRemoveObject(idx);
        }, 1000); 
        return {
          ...prev,
          [ingredientKey]: {
            ...prev[ingredientKey],
            count: prev[ingredientKey].count - 1
          }
        };
      });
      return ingredient.timed ? prevCount : 0;
    });
  };


  // Handle clicking on an ingredient in the recipe board
  const handleIngredientClick = (ingredientKey) => {
    const ingredient = ingredients[ingredientKey];
    const currentIndex = playAreaRef.current.getObjects().length;
    
    if (ingredientKey === 'Toast') {
      setActiveToastCount(prevCount => {
        const totalToasts = prevCount.left + prevCount.right;
        if (totalToasts < 2) {
          const side = prevCount.left <= prevCount.right ? 'left' : 'right';
          handleAddObject(
            <TimedObject 
              key={`${ingredientKey}-${side}-${currentIndex}`}
              intervals={ingredient.intervals}
              object={React.cloneElement(ingredient.component, { key: `${ingredientKey}-${side}-${currentIndex}` })}
              onRemove={(clickedCorrectly) => handleIngredient(ingredientKey, side, currentIndex, clickedCorrectly)}
              isInvisible={true}
            />,
            `Toaster-${side.charAt(0).toUpperCase() + side.slice(1)}`
          );
          playAreaRef.current.updateToasterState(side, 1);
          return { ...prevCount, [side]: prevCount[side] + 1 };
        }
        return prevCount;
      });
    } else if (ingredient.timed) {
      handleAddObject(
        <TimedObject 
          key={`${ingredientKey}-${currentIndex}`}
          intervals={ingredient.intervals}
          object={React.cloneElement(ingredient.component, { 
            key: `${ingredientKey}-${currentIndex}`,
            changed: true
          })}
          onRemove={(clickedCorrectly) => handleIngredient(ingredientKey, null, currentIndex, clickedCorrectly)}
        />,
        ingredient.cookingArea

      );
    } else {
      setChoppingBoardCount(prevCount => {
        if (prevCount === 0) {
          handleAddObject(
            React.cloneElement(ingredient.component, {
              key: `${ingredientKey}-${currentIndex}`,
              onClick: () => handleIngredient(ingredientKey, null, currentIndex, true),
              cookingArea: "CuttingBoard"
            }),
            'CuttingBoard'
          );
          return 1;
        }
        return prevCount;
      });
    }
  };


  // Check for level completion
  useEffect(() => {
    const allIngredientsUsed = Object.values(ingredients).every(ing => ing.count === 0);
    if (allIngredientsUsed) {
      playSound('winSound');
      handleSuccess();
    }
  }, [ingredients, playSound]);

  // Navigation handlers
  const handleClose = () => navigate('/level-select');
  const handleNext = () => navigate(`/level${levelNumber + 1}`);
  const handleRetry = () => navigate(0);

  // Play area configuration
  const areas = (
    <PlayArea 
      recipe={levelRecipe}
      ref={playAreaRef}
      imageSrc={kitchen}
      clock={clockDisplay}
      mapAreaName={'areas'}
      zones={[
        { shape: 'rect', coords: [40, 30, 60, 65], href: '/level-template', alt: 'Stove' },
        { shape: 'rect', coords: [10, 40, 30, 65], href: '/level-template', alt: 'CuttingBoard' },
        { shape: 'rect', coords: [14, 25, 34, 38], href: '/level-template', alt: 'Bench1' },
        { shape: 'rect', coords: [67, 25, 30, 70], href: '/level-template', alt: 'Bench2' },
        { shape: 'rect', coords: [75, 28, 85, 35], href: '/level-template', alt: 'Toaster' },
        { shape: 'rect', coords: [75, 25, 80, 35], href: '/level-template', alt: 'Toaster-Left' },
        { shape: 'rect', coords: [80, 25, 85, 35], href: '/level-template', alt: 'Toaster-Right' },
        { shape: 'rect', coords: [35, 75, 65, 95], href: '/level-template', alt: 'Oven' }
      ]}
      initialObjects={[]}
    />
  );


  return (
    <div className="level-template">
         {areas}
      {showIntro && (
        <IntroScreen 
          recipeName={recipe.name}
          recipeImg={recipe.image}
          timeLimit={timeLimit}
          onStart={handleStartLevel}
        />
      )}
      {showSuccess && (
        <SuccessScreen 
          timeTaken={timeTaken} 
          levelNumber={levelNumber} 
          recipeName={recipe.name}
          onClose={handleClose} 
          onRetry={handleRetry}
          onNext={handleNext}
          recipeImg={recipe.image}
          starScore={starScore}
        />
      )}
      {showFailure && (
        <FailureScreen 
          timeTaken={timeTaken} 
          levelNumber={levelNumber} 
          recipeName={recipe.name}
          onClose={handleClose} 
          onRetry={handleRetry}
        />
      )}
    </div>
  );
};

export default LevelTemplate;