// FailureScreen.js
import React from 'react';
import PropTypes from 'prop-types';
import './navigation/pages/endScreens.css';

const FailureScreen = ({ timeTaken, levelNumber, recipeName, onClose, onRetry }) => {
  return (
    <div className="overlay-screen">
    <div className="overlay-content">
      <h2 className="overlay-title">Level {levelNumber} Failed!</h2>
      <p className="overlay-info">Recipe: {recipeName}</p>
      <p className="overlay-info">Time Taken: {timeTaken}</p>
      <div className="overlay-button-container">
        <button className="overlay-button retry-button" onClick={onRetry}>Retry Level</button>
        <button className="overlay-button level-select-button" onClick={onClose}>Level Select</button>
      </div>
    </div>
  </div>
  );
};

FailureScreen.propTypes = {
  timeTaken: PropTypes.string.isRequired,
  levelNumber: PropTypes.number.isRequired,
  recipeName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default FailureScreen;