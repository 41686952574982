import React, { useState, useEffect } from "react";

import CookingTimer from "../CookingTimer";

import empty from "../../assets/images/cookware/ToasterEmpty.png";
import leftLoaded from "../../assets/images/cookware/ToasterLeft.png";
import rightLoaded from "../../assets/images/cookware/ToasterRight.png";
import bothLoaded from "../../assets/images/cookware/ToasterFull.png";

// const toasterImages = [empty, loaded1, loaded2];

const Toaster = ({ leftState, rightState }) => {
  const [dimensions, setDimensions] = useState({ width: '10vw', height: '15vw', top: '70%', left: '75%' });

  // Adjust the size based on the window's width
  const handleResize = () => {
    const newWidth = Math.min(window.innerWidth * 0.10, 180); // Scale width by 10% of viewport width, max 180px
    const newHeight = newWidth * (15 / 10); // Maintain a consistent aspect ratio (10em width : 15em height)
    const newTop = window.innerHeight * 0.7; // Keep 70% from the top of the viewport
    const newLeft = window.innerWidth * 0.75; // Keep 75% from the left of the viewport

    setDimensions({ 
      width: `${newWidth}px`, 
      height: `${newHeight}px`, 
      top: newTop, 
      left: newLeft  
    });
  };

  useEffect(() => {
    handleResize(); // Initialize dimensions when component mounts
    window.addEventListener('resize', handleResize); // Add event listener for resizing

    return () => window.removeEventListener('resize', handleResize); // Clean up listener on unmount
  }, []);
  
  let imageToUse;
  if (leftState === 0 && rightState === 0) {
    imageToUse = empty;
  } else if (leftState === 1 && rightState === 0) {
    imageToUse = leftLoaded;
  } else if (leftState === 0 && rightState === 1) {
    imageToUse = rightLoaded;
  } else {
    imageToUse = bothLoaded;
  }

  return (
    <img 
      src={imageToUse} 
      alt={`Toaster (Left: ${leftState ? 'Loaded' : 'Empty'}, Right: ${rightState ? 'Loaded' : 'Empty'})`}
      style={{ 
        width: dimensions.width, 
        height: dimensions.height, 
        top: dimensions.top, 
        left: dimensions.left,
      }} 
    />
  );
};

export default Toaster;