import React from 'react';
import LevelTemplate from './components/navigation/levelTemplate';
import Navigation from './components/Navigation';

const testLevel = <LevelTemplate timesUp={30} />

function App() {
  return (
    <div>
      <div>
        <Navigation level={testLevel} />
      </div>
    </div>
  );
}

export default App;