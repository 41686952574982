import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 

const UserAuth = () => {
    const [name, setName] = useState('');
    const [isLogin, setIsLogin] = useState(true);  // To toggle between login and sign-up
    const navigate = useNavigate(); // Initialize the navigate function

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (isLogin) {
            // Existing user login
            try {
                const response = await fetch('http://localhost:3001/login/name=' + name, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const result = await response.json();
                if (response.ok) {
                    localStorage.setItem('userID', result.userID);
                    alert('Login successful: ' + result.message);
                    navigate('/'); // Navigate to home after login
                } else {
                    alert('Error: ' + result.error);
                }
            } catch (err) {
                alert('Login failed: ' + err.message);
            }
        } else {
            // New user sign-up
            try {
                const response = await fetch('http://localhost:3001/add_user/name=' + name, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const result = await response.json();
                if (response.ok) {
                    localStorage.setItem('userID', result.userID);
                    alert('User created: ' + result.message);
                    navigate('/'); // Navigate to home after sign-up
                } else {
                    alert('Error: ' + result.error);
                }
            } catch (err) {
                alert('Failed to create user: ' + err.message);
            }
        }
    };

    // Toggle between login and sign-up
    const toggleForm = () => {
        setIsLogin(!isLogin);
        setName('');  // Clear the input field when switching
    };

    return (
        <div>
            <h2>{isLogin ? 'Login' : 'Sign Up'}</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name"
                    required
                />
                <button type="submit">{isLogin ? 'Login' : 'Sign Up'}</button>
            </form>

            <p>
                {isLogin
                    ? "Don't have an account? "
                    : 'Already have an account? '}
                <button onClick={toggleForm}>
                    {isLogin ? 'Sign up' : 'Login'}
                </button>
            </p>
        </div>
    );
};

export default UserAuth;
